import { ThemeConfig, extendTheme } from "@chakra-ui/react";

const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const colors = {
    redClrs: "#FF4136",
    primary: "#CD3F25",
    background: "#d53548",
};

const fonts = {
    body: "JetBrains Mono",
    heading: "JetBrains Mono",
};

const styles = {
    global: {
        body: {
            bg: "white",
            color: "#656565",
            fontSize: "13px",
            fontWeight: 400,
        },
    },
};

const layerStyles = {
    absolute: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
};

export const theme = extendTheme({
    config,
    colors,
    fonts,
    styles,
    layerStyles,
});

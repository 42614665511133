import { Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Folder from '../assets/images/folder.png';
import Page from '../assets/images/page.png';
import { routeNames } from '../services/routes';
import { Icon } from '../shared/Icon';

function Portfolio() {
    return (
        <Stack direction={['column', 'row']} spacing={[8, 12]}>
            <Link to="https://build21.io" target="_blank">
                <Icon src={Page} text="build21" />
            </Link>

            <Link to="https://demo.homex.dev" target="_blank">
                <Icon src={Page} text="home x" />
            </Link>

            <Link to="https://app.launchpoint.fi" target="_blank">
                <Icon src={Page} text="launchpoint" />
            </Link>

            <Link to="https://fairgame.fun" target="_blank">
                <Icon src={Page} text="fairgame" />
            </Link>

            {/* <Link to="https://pepe-memecoin.vercel.app/" target="_blank">
                <Icon src={Page} text="memecoin" />
            </Link> */}

            <Link to={`/${routeNames.nextfarm}`}>
                <Icon src={Folder} text="nextfarm" />
            </Link>
        </Stack>
    );
}

export default Portfolio;

import { Box, Center, Image, SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import Photo1 from '../assets/nextfarm/1.png';
import Photo2 from '../assets/nextfarm/2.png';
import Photo3 from '../assets/nextfarm/3.png';
import Photo4 from '../assets/nextfarm/4.png';
import { WithShadow } from '../shared/WithShadow';

const sources = [Photo1, Photo2, Photo3, Photo4];

function Carousel() {
    const [modalSource, setModalSource] = useState<string>();

    const onImageClick = (src: string) => {
        setModalSource(src);
    };

    return (
        <Center>
            <SimpleGrid pt={[8, 0]} columns={[1, 2]} spacing={[4, 8]}>
                {sources.map((src, index) => (
                    <Box key={index} onClick={() => onImageClick(src)}>
                        <WithShadow shadowColor="#999">
                            <Image src={src} w={['60vw', '32vw']} cursor="pointer" />
                        </WithShadow>
                    </Box>
                ))}
            </SimpleGrid>

            {modalSource && (
                <Center
                    position="fixed"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    zIndex={9999}
                    backgroundColor="#00000090"
                    onClick={() => setModalSource(undefined)}
                >
                    <Image
                        src={modalSource}
                        w="86vw"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Center>
            )}
        </Center>
    );
}

export default Carousel;

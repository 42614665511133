import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './services/routes';

function App() {
    const renderRoutes = (routes: any[]) => {
        return routes.map((route, index) => (
            <Route path={route.path} key={'route-key-' + index} element={<route.component />}>
                {route.children && renderRoutes(route.children)}
                {route.children && <Route path="*" element={<Navigate to={route.children[0].path as string} replace />} />}
            </Route>
        ));
    };

    return <Routes>{renderRoutes(routes)}</Routes>;
}

export default App;

import { Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <Stack flex={0} spacing={3} direction="row" alignItems="center" justifyContent="center">
            <Link to="https://github.com/wzrdx" target="_blank">
                <Text _hover={{ color: 'primary' }} transition="all 0.15s ease-in" userSelect="none">
                    github
                </Text>
            </Link>
            <Text>/</Text>

            <Link to="https://www.linkedin.com/in/serban-macrineanu-4511b8110/" target="_blank">
                <Text _hover={{ color: 'primary' }} transition="all 0.15s ease-in" userSelect="none">
                    linkedin
                </Text>
            </Link>
        </Stack>
    );
}

export default Footer;

import { Box, Stack, Text } from '@chakra-ui/react';
// import { TbArrowLeft } from 'react-icons/tb';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

function Curriculum() {
    return (
        <Stack spacing={6} minH="100vh" p={[4, 8]}>
            <Stack spacing={3} direction="row" alignItems="center">
                <Link to="/">
                    <BiLeftArrowAlt fontSize="18px" />
                </Link>

                <Text fontSize="13px">curriculum vitae</Text>
            </Stack>

            <Stack spacing={4} w={['100%', '50%']}>
                <Text fontWeight={500} color="#111">
                    Summary
                </Text>

                <Text textAlign="justify">
                    I am a seasoned software engineer and full-stack developer with over 8 years of experience in software
                    development. I specialize in front-end development using React or Angular, coupled with comprehensive UI/UX
                    design. I am passionate about developing decentralized applications and smart contracts within the Web3
                    ecosystem and I thrive in fast-paced environments.
                </Text>

                <Text fontWeight={500} color="#111">
                    Experience
                </Text>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Build21
                    </Text>
                    <Text fontWeight={500} fontStyle="italic">
                        Full Stack Developer, Smart Contract Developer
                    </Text>
                    <Text>07/2024</Text>
                    <Text textAlign="justify">
                        Developed a comprehensive, multi-language web application using NextJS, integrating web2 and web3
                        technologies. The application leverages a headless CMS for real-time content management. To enhance user
                        engagement, I implemented a suite of social media features, including posts, likes/dislikes, comments,
                        and community voting.
                        <br />
                        <br />
                        The project is a full-stack application, offering authentication options through traditional
                        credentials, oAuth or secure wallet connection. To deliver a fast and seamless user experience, the app
                        utilizes complex server-side rendering techniques, optimizing content delivery and minimizing load
                        times.
                    </Text>
                </Box>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Home X
                    </Text>
                    <Text fontWeight={500} fontStyle="italic">
                        Full Stack Developer, Smart Contract Developer
                    </Text>
                    <Text>10/2022</Text>
                    <Text textAlign="justify">
                        Co-founded and built a web3 NFT-based game on the MultiversX blockchain. The project integrates our
                        various NFT collections and tokens into an idle browser game, enabling players to stake tokens, complete
                        quests, compete for prizes and earn achievements and rewards. It utilizes multiple smart contracts and a
                        back-end system.
                    </Text>
                </Box>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Nextfarm
                    </Text>
                    <Text fontWeight={500} fontStyle="italic">
                        Front-end Developer
                    </Text>
                    <Text>07/2018 - 01/2022</Text>
                    <Text textAlign="justify">
                        Played a pivotal role as co-founder in a dynamic agritech startup. Successfully developed a robust and
                        innovative farm management system using the Angular framework to create a modular and scalable
                        application. <br />
                        <br />
                        Seamlessly integrated satellite imagery, farm data, real-time weather conditions, and agronomic
                        knowledge to provide intelligent and actionable insights for farmers. Demonstrated expertise in managing
                        complex geospatial data, leveraging the OpenLayers library to efficiently visualize and display large
                        agriculture datasets. <br />
                        <br />
                        Developed a user-friendly tool for loading, customizing, and managing farm data, including field shapes,
                        merging/ splitting, and adding/removing fields. <br />
                        <br />
                        Contributed significantly to the development of a soil mapping module integrating sensor and lab data to
                        generate advanced soil maps, empowering farmers with valuable information for informed decision-making.
                        <br />
                        <br />
                        Implemented color mapping of Normalized Difference Vegetation Index (NDVI) data, providing a visually
                        intuitive representation of vegetation health and facilitating efficient crop management.
                    </Text>
                </Box>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Intellicrops
                    </Text>
                    <Text fontWeight={500} fontStyle="italic">
                        Front-end Developer
                    </Text>
                    <Text>09/2017</Text>
                    <Text textAlign="justify">
                        Co-founded and developed a cutting-edge agritech business-to-business solution that revolutionizes
                        agriculture portfolio management for insurance companies. Successfully integrated satellite imagery and
                        weather data into a comprehensive system designed to mitigate risks and optimize insurance strategies.
                        <br />
                        <br />
                        Implemented robust user management, hierarchy, and permissions to facilitate efficient collaboration and
                        data access within agricultural organizations & teams.
                        <br />
                        <br />
                        Developed a complex task management system enabling employees to store and organize critical field data,
                        including images, documents, and precise geospatial markers.
                        <br />
                        <br />
                        Optimized the storage and retrieval of vast historical datasets, providing insurance companies with a
                        deep understanding of crop evolution over multiple years.
                        <br />
                        <br />
                        Engineered innovative solutions for visualizing large-scale datasets on maps, empowering companies to
                        gain a comprehensive overview of their entire portfolio at a glance.
                    </Text>
                </Box>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Luxoft
                    </Text>
                    <Text fontWeight={500} fontStyle="italic">
                        Back-end Developer
                    </Text>
                    <Text>06/2016 - 08/2018</Text>
                    <Text textAlign="justify">
                        Successfully contributed to the maintenance and development of complex telecommunications applications.
                        Leveraged C++ and .NET framework to provide robust back-end solutions for a large-scale legacy project
                        serving multiple customer support centers. <br />
                        <br />
                        Demonstrated strong problem-solving skills by effectively resolving critical bugs and enhancing existing
                        features to meet evolving user demands. Contributed to the project's overall stability and performance
                        by implementing optimizations and best practices.
                    </Text>
                </Box>

                <Text fontWeight={500} color="#111">
                    Education
                </Text>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Politehnica University of Bucharest
                    </Text>
                    <Text fontWeight={500} fontStyle="italic">
                        Bachelor's Degree, Computer Science
                    </Text>
                    <Text>2013 - 2017</Text>
                </Box>

                <Text fontWeight={500} color="#111">
                    Technologies
                </Text>

                <Text>
                    Adobe Photoshop • Angular • AWS • Blockchain • Express • Gitlab • Linux • MongoDB • NextJS • NodeJS •
                    Postgres • React • Rust • Sequelize • Smart contracts • Tailwind • Typescript • UI/UX • Unity
                </Text>

                <Text fontWeight={500} color="#111">
                    Projects
                </Text>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Discord Roles Bot
                    </Text>
                    <Text textAlign="justify">
                        Built a bot that enables users in a Discord server to obtain roles based on the number of NFTs staked
                        within a smart contract on the blockchain.
                    </Text>
                </Box>

                <Box>
                    <Text fontWeight={500} color="#444">
                        NFT Minting App
                    </Text>
                    <Text textAlign="justify">
                        Developed a smart contract and front-end app facilitating the launch and minting of an NFT collection on
                        the MultiversX blockchain, across multiple phases.
                    </Text>
                </Box>

                <Text fontWeight={500} color="#111">
                    Skills
                </Text>

                <Box>
                    <Text fontWeight={500} color="#444">
                        UI/UX & Web Design
                    </Text>
                    <Text textAlign="justify">
                        I am passionate about designing and building web apps or websites from scratch, independently, without
                        the need for collaboration with a designer. I enjoy gathering ideas and inspiration from various
                        projects, utilizing them to craft unique user interfaces and experiences tailored to the specific
                        requirements of each project undertaken.
                    </Text>
                </Box>

                <Box>
                    <Text fontWeight={500} color="#444">
                        Smart Contract Development
                    </Text>
                    <Text textAlign="justify">
                        I am proficient in developing intricate Rust-based smart contracts tailored for non-EVM blockchains like
                        MultiversX or Solana.
                    </Text>
                </Box>
            </Stack>
        </Stack>
    );
}

export default Curriculum;

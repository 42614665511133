import { Image, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent, PropsWithChildren } from 'react';

export const Icon: FunctionComponent<
    PropsWithChildren<{
        src: string;
        text: string;
    }>
> = ({ src, text }) => {
    return (
        <Stack
            spacing={2.5}
            p="4px"
            alignItems="center"
            cursor="pointer"
            _active={{ outline: 'black dotted 2px' }}
            _focus={{ outline: 'black dotted 2px' }}
            minW="87.5px"
        >
            <Image src={src} width="64px" height="64px" style={{ imageRendering: 'pixelated' }} />
            <Text color="black" userSelect="none" textAlign="center">
                {text}
            </Text>
        </Stack>
    );
};

import './global.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraBaseProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <ChakraBaseProvider theme={theme}>
            <ColorModeScript initialColorMode="light" />
            <App />
        </ChakraBaseProvider>
    </BrowserRouter>
);

import { Box, Center, Stack, Text } from '@chakra-ui/react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../services/routes';
import Footer from './Footer';

function Layout() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page
    };

    return (
        <Stack position="relative" spacing={6} minH="100vh" p={[6, 8]}>
            {!!location.pathname.replaceAll('/', '') && (
                <Stack position="absolute" left={[6, 8]} top={[6, 8]} spacing={3} direction="row" alignItems="center">
                    <Box onClick={handleGoBack} cursor="pointer">
                        <BiLeftArrowAlt fontSize="18px" />
                    </Box>

                    <Text fontSize="13px">{location.pathname.replaceAll('/', '')}</Text>
                </Stack>
            )}

            <Stack flex={1}>
                {location.pathname === routeNames.root && (
                    <Center display={['flex', 'none']} flex={0}>
                        <Text userSelect="none">wzrd | full-stack engineer</Text>
                    </Center>
                )}

                <Center display={['none', 'flex']} flex={0}>
                    <Text userSelect="none">wzrd | full-stack engineer</Text>
                </Center>

                <Center flex={1}>
                    <Outlet />
                </Center>
            </Stack>

            <Footer />
        </Stack>
    );
}

export default Layout;

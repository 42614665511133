import Carousel from '../components/Carousel';
import Curriculum from '../components/Curriculum';
import Desktop from '../components/Desktop';
import Layout from '../components/Layout';
import Portfolio from '../components/Portfolio';

export const routeNames = {
    root: '/',
    cv: 'curriculumvitae',
    portfolio: 'portfolio',
    nextfarm: 'nextfarm',
};

export const routes = [
    {
        path: routeNames.root,
        component: Layout,
        children: [
            {
                path: routeNames.root,
                component: Desktop,
            },
            {
                path: routeNames.portfolio,
                component: Portfolio,
            },
            {
                path: routeNames.nextfarm,
                component: Carousel,
            },
        ],
    },
    {
        path: routeNames.cv,
        component: Curriculum,
    },
];

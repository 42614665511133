import { Box, Flex } from '@chakra-ui/react';
import { FunctionComponent, PropsWithChildren } from 'react';

const OFFSET = '2px';
const BORDER_WIDTH = '2px';

export const WithShadow: FunctionComponent<PropsWithChildren<{ shadowColor: string }>> = ({ children, shadowColor }) => {
    return (
        <Box position="relative">
            <Flex position="relative" borderColor={shadowColor} borderWidth={BORDER_WIDTH} borderStyle="solid" zIndex={2}>
                {children}
            </Flex>

            <Box
                position="absolute"
                top={OFFSET}
                right={`-${OFFSET}`}
                bottom={`-${OFFSET}`}
                left={OFFSET}
                backgroundColor={shadowColor}
                zIndex={1}
            >
                {children}
            </Box>
        </Box>
    );
};
